import React from 'react'
import './App.css'

function App() {
  return (
    <div className="App">
      <h1>Hello</h1>
      <p>v0.0.1</p>
    </div>
  )
}

export default App
